<template>
  <div class="page">
    <nav-top :index="0" @cityLoaded="handleCityLoaded"></nav-top>
    <div class="banner-info">
      <img src="/static/images/home_banner.jpg" class="bg">
      <div class="search-banner">
        <div class="search-data">
          <div class="slogan">
            <img src="/static/images/by_tit.png" alt="">
          </div>
          <div class="search-info">
            <div class="search">
              <input type="text" placeholder="输入您要搜索的内容" v-model="keyword">
            </div>
            <div class="btn" @click="handleSearchClick">
              <img src="/static/images/fdj_ico1.png" alt="">
            </div>
          </div>
          <div class="service" @click="handleChatClick">
            <img src="/static/images/kf_ico1.png" alt="">
            <span>在线咨询</span>
          </div>
        </div>
        <div class="banner-data">
          <div class="banner" v-loading="index_loading">
            <el-carousel height="350px" indicator-position="none">
              <el-carousel-item v-for="item in banner_list" :key="item.id" @click="handleBannerClick(item)">
                <div class="item">
                  <img :src="item.image" alt="">
                  <p class="title">{{ item.title }}</p>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="general-tool">
      <div class="tool-info">
        <div class="title-desc">
          <div class="title">
            <span>常用工具</span>
          </div>
          <div class="desc">
            <span>COMMON TOOLS</span>
          </div>
        </div>
        <div class="tool-item">
          <div class="item" v-loading="hot_article.loading">
            <div class="tool-title">
              <img src="/static/images/lsrd_ico.png" alt="">
              <span>楼市热点</span>
            </div>
            <div class="item-tab">
              <div class="tab" @click="handleToolTab001Click(index)" :class="{active:index === hot_article.index}"
                   v-for="(item,index) in hot_article.cate" :key="item.id">
                <span>{{ item.name }}</span>
              </div>
            </div>
            <div class="item-list">
              <div class="list" v-for="(item,index) in hot_article.list[hot_article.index]" :key="item.id" @click="handleArticle001Click(index)">
                <div class="key">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="title">
                  <span>{{ item.title }}</span>
                </div>
                <div class="tag">
                  <span class="new" v-if="item.tag === 1">{{ item.tag_text }}</span>
                  <span class="fff" v-if="item.tag === 2">{{ item.tag_text }}</span>
                  <span class="hot" v-if="item.tag === 3">{{ item.tag_text }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item" v-loading="index_loading">
            <div class="tool-title">
              <img src="/static/images/bb_ico.png" alt="">
              <span>报备</span>
            </div>
            <div class="item-tab">
              <div class="tab active">
                <span>热门楼盘</span>
              </div>
            </div>
            <div class="item-list">
              <div class="list" v-for="(item,index) in top_hot_project_list" :key="item.id" @click="handleHotProjectClick(index)">
                <div class="key">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="title">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item" v-loading="weilu_article.loading">
            <div class="tool-title">
              <img src="/static/images/llbd_ico.png" alt="">
              <span>论楼宝典</span>
            </div>
            <div class="item-tab">
              <div class="tab" @click="handleToolTab003Click(index)" :class="{active:index === weilu_article.index}"
                   v-for="(item,index) in weilu_article.cate" :key="item.id">
                <span>{{ item.name }}</span>
              </div>
            </div>
            <div class="item-list">
              <div class="list" v-for="(item,index) in weilu_article.list[weilu_article.index]" :key="item.id" @click="handleArticle003Click">
                <div class="key">
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="title">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hot-property" v-if="hot_project_list.length">
      <div class="property-info">
        <div class="title-desc">
          <div class="title">
            <span>热门楼盘</span>
          </div>
          <div class="desc">
            <span>POPULAR REAL ESTATE</span>
          </div>
        </div>
        <div class="property-list">
          <div class="list" v-for="(item,index) in hot_project_list" :key="index" @click="handlePropertyClick(item.id)">
            <div class="pic">
              <img :src="item.image" alt="">
            </div>
            <div class="title">
              <span>{{ item.name }}</span>
            </div>
            <div class="price-info">
              <div class="tit">
                <span>均价</span>
              </div>
              <div class="price">
                <span>{{ item.base_data.price_pending == 1 ? '待定' : item.base_data.price_start }}</span>
              </div>
              <div class="tit" v-show="item.base_data.price_pending != 1">
                <span>元/㎡</span>
              </div>
            </div>
            <div class="desc">
              <span>[{{ item.city.name }}周边 {{ item.area.name }}] {{ item.base_data.address }}</span>
            </div>
            <div class="desc">
              <span>户型：{{ item.base_data.types_for_sale }}  | 建筑面积：{{
                  item?.base_data?.area_start
                }}㎡-{{ item?.base_data?.area_end }}㎡</span>
            </div>
            <div class="tag-list">
              <div class="tag orange" v-if="item.base_data.primary_tag">
                <span>{{ item.base_data.primary_tag }}</span>
              </div>
              <div class="tag blue">
                <span v-if="item.base_data.product_status_id === 1">全城热销中</span>
                <span v-if="item.base_data.product_status_id === 2">火爆加推</span>
                <span v-if="item.base_data.product_status_id === 3">预售进行中</span>
                <span v-if="item.base_data.product_status_id === 4">待售</span>
              </div>
              <div class="tag gray">
                <span v-for="(secondary_tag,secondary_tag_index) in item.base_data.secondary_tags"
                      :key="secondary_tag_index">{{ secondary_tag.tag }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="paging-info">-->
        <!--          <div class="pagination">-->
        <!--            <div class="num active">-->
        <!--              <span>1</span>-->
        <!--            </div>-->
        <!--            <div class="num">-->
        <!--              <span>2</span>-->
        <!--            </div>-->
        <!--            <div class="num">-->
        <!--              <span>3</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="paging">-->
        <!--            <div class="cut">-->
        <!--              <span class="left"></span>-->
        <!--            </div>-->
        <!--            <div class="cut">-->
        <!--              <span class="right"></span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="floor-data">
      <div class="title-desc">
        <div class="title">
          <span>论楼榜</span>
        </div>
        <div class="desc">
          <span>SALES LIST</span>
        </div>
      </div>
      <div class="floor-info" v-if="rank_list.length">
        <div class="floor-title">
          <div class="title">
            <span>销售之星</span>
          </div>
          <div class="more">
            <span class="right-arrows"></span>
          </div>
        </div>
        <div class="sales-list">
          <div class="list" v-for="(item,index) in rank_list" :key="index">
            <div class="pic">
              <el-image :src="item.avatar" fit="cover" alt=""/>
            </div>
            <div class="name-tag">
              <div class="name">
                <span>{{ item.name }}</span>
              </div>
              <div class="tag">
                <span>{{ item.description }}</span>
              </div>
            </div>
            <div class="consult"  @click="handleChatClick">
              <img src="/static/images/xx_ico1.png" alt="">
              <span>在线咨询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="floor-info" v-if="store_list.length">
        <div class="floor-title">
          <div class="title">
            <span>门店排名</span>
          </div>
        </div>
        <div class="shop-list">
          <div class="list" v-for="(item,index) in store_list" :key="index">
            <div class="logo">
              <el-image :src="item.image" alt="" fit="contain"/>
            </div>
            <div class="name">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="matrix-info">
      <div class="title-desc">
        <div class="title">
          <span>论楼矩阵</span>
        </div>
        <div class="desc">
          <span>SERVICE CROWD</span>
        </div>
      </div>
      <div class="matrix-image">
        <img :src="service_crowd">
      </div>
      <!--      <div class="matrix-list">-->
      <!--        <div class="list">-->
      <!--          <img src="/static/images/jzgs_bg.png" class="bg">-->
      <!--          <div class="bottom-title">-->
      <!--            <div class="title">-->
      <!--              <span>代理公司</span>-->
      <!--              <span class="line"></span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="list" style="margin-left: -1px">-->
      <!--          <img src="/static/images/jzgfz_bg.png" class="bg">-->
      <!--          <div class="top-title">-->
      <!--            <div class="title">-->
      <!--              <span>购房者</span>-->
      <!--              <span class="line"></span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="list">-->
      <!--          <img src="/static/images/jzzfz_bg.png" class="bg">-->
      <!--          <div class="bottom-title">-->
      <!--            <div class="title">-->
      <!--              <span>租房者</span>-->
      <!--              <span class="line"></span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="list">-->
      <!--          <img src="/static/images/jzjjr_bg.png" class="bg">-->
      <!--          <div class="top-title">-->
      <!--            <div class="title">-->
      <!--              <span>经纪人</span>-->
      <!--              <span class="line"></span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="contact-data">
      <div class="contact-way">
        <div class="way">
          <div class="pic">
            <img :src="pc_index_applet.img" alt="">
          </div>
          <div class="item">
            <div class="title">
              <span>{{ pc_index_applet.title }}</span>
            </div>
            <div class="desc">
              <span>{{ pc_index_applet.desc }}</span>
            </div>
          </div>
        </div>
        <div class="way">
          <div class="pic">
            <img :src="pc_index_mp.img" alt="">
          </div>
          <div class="item">
            <div class="title">
              <span>{{ pc_index_mp.title }}</span>
            </div>
            <div class="desc">
              <span>{{ pc_index_mp.desc }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'home',
  components: {FooterBar, NavTop},
  data() {
    return {
      keyword: '',
      index_loading: false,
      banner_list: [],
      store_list: [],
      rank_list: [],
      hot_project_list: [],
      hot_project_current_page: 1,
      hot_project_last_page: 1,
      pc_index_applet: {
        desc: '',
        img: '',
        title: '',
      },
      pc_index_mp: {
        desc: '',
        img: '',
        title: '',
      },
      service_crowd: '',
      hot_article: {
        cate: [],
        index: 0,
        list: [],
        loading: false
      },
      weilu_article: {
        cate: [],
        index: 0,
        list: [],
        loading: false
      }
    }
  },
  methods: {
    async fetchData() {
      try {
        this.index_loading = true
        let res = await this.http({
          url: '/api/index/index',
          method: 'GET',
          params: {
            type: 1
          }
        })
        this.banner_list = res.data.banner
        this.store_list = res.data.store
        this.rank_list = res.data.rank


        for (let index in res.data.hot_project.data) {
          res.data.hot_project.data[index].base_data.primary_tag = JSON.parse(res.data.hot_project.data[index].base_data.primary_tags)[0]?.tag || null
          res.data.hot_project.data[index].base_data.secondary_tags = JSON.parse(res.data.hot_project.data[index].base_data.secondary_tags).splice(0, 2)
        }
        this.hot_project_list = res.data.hot_project.data
        this.hot_project_current_page = res.data.hot_project.current_page
        this.hot_project_last_page = res.data.hot_project.last_page

        this.pc_index_applet.desc = res.data.pc_index_applet_desc
        this.pc_index_applet.img = res.data.pc_index_applet_img
        this.pc_index_applet.title = res.data.pc_index_applet_title
        this.pc_index_mp.desc = res.data.pc_index_mp_desc
        this.pc_index_mp.img = res.data.pc_index_mp_img
        this.pc_index_mp.title = res.data.pc_index_mp_title
        this.service_crowd = res.data.pc_index_service_crowd
      } catch (e) {
        console.log(e)
      } finally {
        this.index_loading = false
      }
    },
    async fetchHotArticleData() {
      try {
        this.hot_article.loading = true
        this.hot_article.list = []

        let resCate = await this.http({
          url: '/api/article/hotArticleCateList',
          method: 'GET'
        })

        this.hot_article.cate = resCate.data

        let resList = await this.http({
          url: '/api/article/hotArticleList',
          method: 'GET',
          params: {
            cate_id: this.hot_article.cate[0].id,
            page: 1
          }
        })

        this.hot_article.list[0] = resList.data.list.slice(0, 3)
      } catch (e) {
        console.log(e)
      } finally {
        this.hot_article.loading = false
      }
    },
    async fetchWeiluArticleData() {
      try {
        this.weilu_article.loading = true
        this.weilu_article.list = []

        let resCate = await this.http({
          url: '/api/article/collectionIndex',
          method: 'GET'
        })

        this.weilu_article.cate = resCate.data.slice(0, 4)

        let resList = await this.http({
          url: '/api/article/collectionList',
          method: 'GET',
          params: {
            cate_id: this.weilu_article.cate[0].id,
            page: 1
          }
        })

        this.weilu_article.list[0] = resList.data.list.slice(0, 3)

      } catch (e) {
        console.log(e)
      } finally {
        this.weilu_article.loading = false
      }
    },
    handleCityLoaded() {
      this.fetchData()
      this.fetchHotArticleData()
      this.fetchWeiluArticleData()
    },
    handleArticle001Click(index){
      let id = this.hot_article.list[this.hot_article.index][index].id

      this.$router.push({
        path:'/hotspotDetails',
        query:{
          id:id
        }
      })
    },
    handleHotProjectClick(index){
      this.$router.push({
        path:'/HousesHome',
        query:{
          id:this.top_hot_project_list[index].id
        }
      })
    },
    handleArticle003Click(){
      this.$router.push({
        path:'/valuable',
      })
    },
    async handleToolTab001Click(index) {
      try {
        this.hot_article.loading = true

        if (this.hot_article.list[index] === undefined) {
          let resList = await this.http({
            url: '/api/article/hotArticleList',
            method: 'GET',
            params: {
              cate_id: this.hot_article.cate[index].id,
              page: 1
            }
          })

          this.hot_article.list[index] = resList.data.list.slice(0, 3)
        }

        this.hot_article.index = index
      } catch (e) {
        console.log(e)
      } finally {
        this.hot_article.loading = false
      }
    },
    async handleToolTab003Click(index) {
      try {
        this.weilu_article.loading = true
        if (this.weilu_article.list[index] === undefined) {
          let resList = await this.http({
            url: '/api/article/collectionList',
            method: 'GET',
            params: {
              cate_id: this.weilu_article.cate[index].id,
              page: 1
            }
          })

          this.weilu_article.list[index] = resList.data.list.slice(0, 3)
        }

        this.weilu_article.index = index
      } catch (e) {
        console.log(e)
      } finally {
        this.weilu_article.loading = false
      }

    },
    handleBannerClick(item) {
      this.$router.push({
        path: '/hotspotDetails',
        query: {
          id: item.article_id
        }
      })
    },
    handlePropertyClick(id) {
      this.$router.push({
        path: '/HousesHome',
        query: {
          id: id
        }
      })
    },
    handleSearchClick() {
      this.$router.push({
        path: '/newsList',
        query: {
          keyword: this.keyword
        }
      })
    },
    handleChatClick(){
      window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.qq}&site=qq&menu=yes`,'_blank')
    }
  },
  computed: {
    top_hot_project_list() {
      return this.hot_project_list.slice(0, 3)
    }
  }
}
</script>

<style scoped lang="scss">
@import "home.scss";
</style>
